import React, { useState, useEffect } from 'react'
import firebase from '../utils/firebase'
import { Paper, Grid, Container, TextField, Divider, Button, List, ListItem, ListItemText } from '@material-ui/core'
import { css } from '@emotion/core'
import numeral from 'numeral'
import axios from 'axios'
import _ from 'lodash'

import Loader from '../components/Loader'

export default function Dashboard () {

    const [user,setuser] = useState(null)
    const [address,setaddress] = useState('')
    const [list,setlist] = useState([])
    const [selected,setselected] = useState({})
    const [beds,setbeds] = useState([])

    useEffect(() => {
        firebase.userSession().onSnapshot(snap => {
            const data = snap.data()
            setuser(data)
        })
        firebase.getBeds().onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setbeds(data)
        })
    },[])

    const total = _.size(beds);
    const ocupadas = _.filter(beds, o => {
        return o.isAvailable !== true
    })
    const ocupadasintensiva = _.filter(ocupadas, o => {
        return o.isIntensiveTherapy === true
    })
    const ocupadasventilador = _.filter(ocupadas, o => {
        return o.haveFan === true
    })
    const desocupadas = _.filter(beds, o => {
        return o.isAvailable === true
    })
    const desocupadasintensiva = _.filter(desocupadas, o => {
        return o.isIntensiveTherapy === true
    })
    const desocupadasventilador = _.filter(desocupadas, o => {
        return o.haveFan === true
    })
    const totalocupadas = _.size(ocupadas)
    const totaldesocupadas = _.size(desocupadas)

    return user ? (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Nombre de Hospital"
                        defaultValue={user.name}
                        fullWidth
                        variant="outlined"
                        onChange={e => firebase.update('users',user.id,'name',e.target.value)}
                    />
                    <TextField
                        label="Número Telefónico"
                        defaultValue={user.phone}
                        fullWidth
                        variant="outlined"
                        onChange={e => firebase.update('users',user.id,'phone',e.target.value)}
                        css={css`
                            margin-top: 1rem;
                        `}
                    />
                    <TextField
                        label="Email"
                        defaultValue={user.email}
                        fullWidth
                        variant="outlined"
                        disabled
                        css={css`
                            margin-top: 1rem;
                        `}
                    />
                    <Divider css={css`
                        margin: 1rem 0;
                    `} />
                    {!user.address ? <>
                        <h3 css={css`
                            text-align: center;
                            font-size: 1.4rem;
                            margin-bottom: 1rem;
                        `}>Dirección</h3>
                        <TextField
                            label="Dirección"
                            value={address}
                            fullWidth
                            variant="outlined"
                            onChange={e => setaddress(e.target.value)}
                        />
                        <Button onClick={handleSearch} css={css`
                            margin-top: .5rem;
                        `} fullWidth variant="outlined" color="primary">Buscar</Button>
                        {
                            list ? <List>
                                {
                                    list.map((item,index) => <ListItem onClick={() => setselected(item)} selected={selected.place_id === item.place_id} key={index} button>
                                        <ListItemText
                                            primary={item.formatted_address}
                                        />
                                    </ListItem>)
                                }
                            </List> : ''
                        }
                        <Button disabled={selected ? false : true} onClick={handleAddress} css={css`
                            margin-top: .5rem;
                        `} fullWidth variant="contained" color="primary">Seleccionar dirección</Button>
                    </> : <>
                        <h4>Dirección: </h4>
                        <p>{user.address.formatted_address}</p>
                    </>}
                </Grid>
                <Grid item container xs={12} md={8} spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Paper css={css`
                            padding: 2rem 1rem;
                            text-align: center;
                            display: flex;
                            flex-direction: column;
                            height: 100%;
                            justify-content: center;
                            align-items: center;
                        `}>
                            <span>{numeral(total).format('0')}</span>
                            <h3>Camas totales</h3>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper css={css`
                            padding: 2rem 1rem;
                            text-align: center;
                            display: flex;
                            flex-direction: column;
                            height: 100%;
                            justify-content: center;
                            align-items: center;
                        `}>
                            <span>{numeral(totalocupadas).format('0')}</span>
                            <h3>Camas ocupadas</h3>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper css={css`
                            padding: 2rem 1rem;
                            text-align: center;
                            display: flex;
                            flex-direction: column;
                            height: 100%;
                            justify-content: center;
                            align-items: center;
                        `}>
                            <span>{numeral(_.size(ocupadasintensiva)).format('0')}</span>
                            <h3>Camas ocupadas en terapia intensiva</h3>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper css={css`
                            padding: 2rem 1rem;
                            text-align: center;
                            display: flex;
                            flex-direction: column;
                            height: 100%;
                            justify-content: center;
                            align-items: center;
                        `}>
                            <span>{numeral(_.size(ocupadasventilador)).format('0')}</span>
                            <h3>Camas ocupadas con ventiladores</h3>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper css={css`
                            padding: 2rem 1rem;
                            text-align: center;
                            display: flex;
                            flex-direction: column;
                            height: 100%;
                            justify-content: center;
                            align-items: center;
                        `}>
                            <span>{numeral(totaldesocupadas).format('0')}</span>
                            <h3>Camas disponibles</h3>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper css={css`
                            padding: 2rem 1rem;
                            text-align: center;
                            display: flex;
                            flex-direction: column;
                            height: 100%;
                            justify-content: center;
                            align-items: center;
                        `}>
                            <span>{numeral(_.size(desocupadasintensiva)).format('0')}</span>
                            <h3>Camas disponibles en terapia intensiva</h3>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper css={css`
                            padding: 2rem 1rem;
                            text-align: center;
                            display: flex;
                            flex-direction: column;
                            height: 100%;
                            justify-content: center;
                            align-items: center;
                        `}>
                            <span>{numeral(_.size(desocupadasventilador)).format('0')}</span>
                            <h3>Camas disponibles con ventiladores</h3>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    ) : <Loader />

    async function handleAddress () {
        try {
            if (selected) {
                if (_.includes(selected.formatted_address, 'Guasave')) {
                    firebase.update('users',user.id,'address',selected).then(() => {
                        alert('Dirección establecida')
                    })
                } else {
                    alert('Esta dirección no corresponde al municipio de Guasave')
                }
            } else {
                alert('Selecciona una dirección para continuar')
            }
        } catch (error) {
            alert(error.message)
        }
    }

    async function handleSearch () {
        if (address) {
            const origin = _.replace(address, ' ', '+');
            axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${origin}&key=AIzaSyCrTl05Ajn7uo9_imbmH6SzslZQk1JW6xs`).then(response => {
                const results = response.data.results
                if (_.size(results) > 1) {
                    setlist(results)
                } else {
                    setlist(results)
                    setselected(results[0])
                }
            }).catch(error => {
                console.log(error)
                alert('Ha ocurrido un error al encontrar la dirección:' + error.message)
            });
        }
    }
}