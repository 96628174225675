import React, { useState, useEffect }  from 'react'
import firebase from '../utils/firebase'
import { Container, Grid, TextField, Button, Table, TableHead, TableRow, TableCell, TableBody, Checkbox } from '@material-ui/core'
import { css } from '@emotion/core'

const Item = ({ data }) => {

    return (
        <TableRow>
            <TableCell align="center"><Checkbox color="primary" checked={data.isAvailable} onClick={() => firebase.update('beds',data.id,'isAvailable', data.isAvailable ? !data.isAvailable : true)}/></TableCell>
            <TableCell align="center">{data.number}</TableCell>
            <TableCell align="center"><Checkbox color="primary" checked={data.isIntensiveTherapy} onClick={() => firebase.update('beds',data.id,'isIntensiveTherapy', data.isIntensiveTherapy ? !data.isIntensiveTherapy : true)} /></TableCell>
            <TableCell align="center"><Checkbox color="primary" checked={data.haveFan} onClick={() => firebase.update('beds',data.id,'haveFan', data.haveFan ? !data.haveFan : true)} /></TableCell>
        </TableRow>
    )
}

export default function Beds () {

    const [number,setnumber] = useState('')
    const [list,setlist] = useState([])

    useEffect(() => {
        firebase.getBeds().orderBy('number').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setlist(data)
        })
    },[])

    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Número / Referencia de Cama"
                        fullWidth
                        variant="outlined"
                        value={number}
                        onChange={e => setnumber(e.target.value)}
                    />
                    <Button onClick={handleSubmit} css={css`
                        margin-top: .8rem;
                    `} color="primary" fullWidth variant="contained">Agregar</Button>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell variant="head" align="center">¿Está disponible?</TableCell>
                                <TableCell variant="head" align="center">Cama</TableCell>
                                <TableCell variant="head" align="center">¿Terapia Intensiva?</TableCell>
                                <TableCell variant="head" align="center">¿Tiene Ventilador?</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                list.map(item => <Item key={item.id} data={item} />)
                            }
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Container>
    )

    async function handleSubmit () {
        try {
            const item = {
                number
            }
            firebase.simpleAdd(item,'beds').then(() => {
                setnumber('')
            })
        } catch (error) {
            alert(error.message)
        }
    }
}