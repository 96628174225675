import React, { useEffect, useState } from 'react'
import firebase from './utils/firebase'
import { Switch, Route, Link } from 'react-router-dom'
import { Divider, List, ListItem, ListItemIcon, ListItemText, AppBar, Toolbar, IconButton, Typography, Hidden, Drawer } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import DashboardIcon from '@material-ui/icons/Dashboard';
import MenuIcon from '@material-ui/icons/Menu';
import HotelIcon from '@material-ui/icons/Hotel';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import Loader from './components/Loader';
import Dashboard from './pages/Dashboard';
import Beds from './pages/Beds';
import Patients from './pages/Patients';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
        display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
}));

export default function App (props) {

    const loc = props.location.pathname.split('/')[1]
    const { container } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [user,setUser] = useState(undefined)

    useEffect(() => {
        firebase.isInitialized().then(user => {
            if (user) {
                firebase.userSession().get().then(snap => {
                    const data = snap.data()
                    if (data.rol === "hospital") {
						if (data.isActive === true) {
                            setUser(data)
                        } else {
                            alert('Solicita al gobierno de tu municipio que active tu hospital para continuar')
							props.history.push('/iniciar-sesion')
                        }
                    } else {
                        firebase.logout().then(() => {
							props.history.push('/iniciar-sesion')
						})
                    }
                })
            } else {
				props.history.push('/iniciar-sesion')
			}
        })
    },[props.history])

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <Divider />
            <List>
                <ListItem selected={loc === '' || loc === undefined ? true : false} component={Link} to="/" button>
                    <ListItemIcon><DashboardIcon/></ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>
				<ListItem selected={loc === 'camas' ? true : false} component={Link} to="/camas" button>
                    <ListItemIcon><HotelIcon/></ListItemIcon>
                    <ListItemText primary="Camas" />
                </ListItem>
                <ListItem selected={loc === 'pacientes' ? true : false} component={Link} to="/pacientes" button>
                    <ListItemIcon><PersonOutlineIcon/></ListItemIcon>
                    <ListItemText primary="Pacientes" />
                </ListItem>
            </List>
        </div>
    )

    return user ? (
        <>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography style={{ color: '#fff', fontWeight: '700' }} variant="h6" noWrap>
                        Panel de Hospital
                    </Typography>
					<IconButton onClick={logOut} style={{ marginLeft: 'auto' }}>
						<ExitToAppIcon/>
					</IconButton>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true,
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {
                    <Switch>
                        <Route path="/" component={Dashboard} exact={true} />
                        <Route path="/camas" component={Beds} exact={true} />
                        <Route path="/pacientes" component={Patients} exact={true} />
                    </Switch>
                }
            </main>
        </>
    ) : <Loader />


	async function logOut () {
		try {
			firebase.logout().then(() => {
				props.history.push('/iniciar-sesion')
			})
		} catch (error) {
			alert(error.message)
		}
	}
}