import React, { useState, useEffect }  from 'react'
import firebase from '../utils/firebase'
import { Container, Table, TableHead, TableRow, TableCell, TableBody, NativeSelect } from '@material-ui/core'
import { css } from '@emotion/core'

const Item = ({ data }) => {

    return (
        <TableRow>
            <TableCell align="center">{data.name}</TableCell>
            <TableCell align="center">{data.address}</TableCell>
            <TableCell align="center">{data.age}</TableCell>
            <TableCell align="center">
                <NativeSelect
                    value={data.status}
                    onChange={e => firebase.update('users',data.id,'status',e.target.value)}
                    fullWidth
                >
                    <option value="">** Pendiente **</option>
                    <option value="positivo">Positivo</option>
                    <option value="curado">Curado</option>
                    <option value="deceso">Deceso</option>
                </NativeSelect>
            </TableCell>
        </TableRow>
    )
}

export default function Patients () {

    const [number,setnumber] = useState('')
    const [list,setlist] = useState([])

    useEffect(() => {
        firebase.getPatients().onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setlist(data)
        })
    },[])

    return (
        <Container>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell variant="head" align="center">Nombre</TableCell>
                        <TableCell variant="head" align="center">Dirección</TableCell>
                        <TableCell variant="head" align="center">Edad</TableCell>
                        <TableCell variant="head" align="center">Estatus</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        list.map(item => <Item key={item.id} data={item} />)
                    }
                </TableBody>
            </Table>
        </Container>
    )

    async function handleSubmit () {
        try {
            const item = {
                number
            }
            firebase.simpleAdd(item,'beds').then(() => {
                setnumber('')
            })
        } catch (error) {
            alert(error.message)
        }
    }
}